import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import style from "./portfolio-single.module.css"

export default ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  return (
    <Layout location={location}>
      <div className={style.artifact}>
        <h1>{post.frontmatter.title}</h1>
        <div className={style.meta}>
          <div>{post.frontmatter.author}</div>
          <div>
            Published{" "}
            {new Date(post.frontmatter.date).toLocaleDateString("en-US", {
              month: "long",
              day: "numeric",
              year: "numeric",
            })}{" "}
          </div>
        </div>
        {post.frontmatter.featimg && (
          <figure className={style.featimg}>
            <Img
              fluid={post.frontmatter.featimg.childImageSharp.fluid}
              alt={post.frontmatter.title}
            />
          </figure>
        )}

        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        author
        featimg {
          childImageSharp {
            fluid(maxWidth: 1360) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
